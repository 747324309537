import Icon from '@/components/atoms/legacy/Icon';
import { convertMakeToIconID } from '@/lib/make';
import { ReactNode } from 'react';

interface NoImageWrapperProps {
  make?: string;
  className?: string;
  fillColor?: string;
  height?: string;
  width?: string;
  renderActions?: ReactNode;
}

export const NoImageWrapper: React.FC<NoImageWrapperProps> = ({
  make = null,
  className = 'm-auto mb-s ',
  fillColor = '#0E326A',
  height = '65px',
  width = '64px',
  renderActions = null,
}) => {
  const iconId = make ? convertMakeToIconID(make) : null;
  return (
    <div className="m-auto">
      <div className={className}>
        {iconId && (
          <Icon
            className="m-auto mb-s"
            fillColor={fillColor}
            height={height}
            width={width}
            iconId={iconId}
          />
        )}
        <div>Image coming soon</div>
      </div>
      {renderActions}
    </div>
  );
};
