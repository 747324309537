import { phone } from 'phone';

export const formatPhone = (phoneNumber: string): string => {
  const res = phone(phoneNumber);
  if (res.isValid) {
    return res.phoneNumber;
  }
  return phoneNumber;
};

export const formatPhoneNumberWithInputMask = (phoneNumber: string): string => {
  const trimmed = phoneNumber.startsWith('+1')
    ? phoneNumber.slice(2)
    : phoneNumber;
  let formattedInput = trimmed.replace(/\D/g, '');
  if (formattedInput.length > 3 && formattedInput.length <= 6) {
    formattedInput = `(${formattedInput.slice(0, 3)}) ${formattedInput.slice(
      3
    )}`;
  } else if (formattedInput.length > 6) {
    formattedInput = `(${formattedInput.slice(0, 3)}) ${formattedInput.slice(
      3,
      6
    )} ${formattedInput.slice(6, 10)}`;
  }
  return formattedInput;
};
